import React from "react";
import { Link } from "react-router-dom";
import UserService from "../services/UserService.js";

const { REACT_APP_REDIRECT_URI } = process.env;

function Navbar() {
  return (
    <div>
      <nav className="navbar navbar-expand-sm navbar-light bg-light">
        <a className="navbar-brand mx-3" href="/" style={{ color: "green" }}>
          {/* <img src={logo192} style={{height:"30px"}}/> */}
          Deepsy{" "}
        </a>

        <div className="navbar-nav ml-auto">
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
            
        
            <li
              className={
                UserService.isLoggedIn()
                  ? " dropdown nav-item active"
                  : "d-none"
              }
            >
             
            
              
                  
                      {window.location.pathname === "/" && (
           <div>
                  <button
                    className="btn btn-sm dropdown-toggle nav-item mt-2"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
        
<i class='fas fa-clipboard-list mr-1'></i>
                    Products
                  </button>

                  <div
                    className="dropdown-menu "
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a className="dropdown-item" href="/fivetest">
                      Testing First 5{" "}
                    </a>
                    <a className="dropdown-item" href="/allsubmission">
                      All submissions
                    </a>
                    <a className="dropdown-item" href="/previousprompts">
                      Previous Prompts Output
                    </a>
                    <a className="dropdown-item" href="/deletefile">
                      Delete Files{" "}
                    </a>
                    <a className="dropdown-item" href="/uploadfile">
                      Upload File
                    </a>
                    <a className="dropdown-item" href="/fileupload">
                      File(upload){" "}
                    </a>
                    <a className="dropdown-item" href="/tokencalmulti">
                      Tokens Calculator(multiple){" "}
                    </a>
                    <a className="dropdown-item" href="/tokencalbatchwise">
                      Tokens Calculator(batch id wise)
                    </a>
                    <a className="dropdown-item" href="/finalize">
                      Finalize
                    </a>
                    <a className="dropdown-item" href="/downloadrejectedfiles">
                      Download Rejected Files
                    </a>

                  </div>
                </div>
              )}  
              
              </li>
              <li
                className={
                  UserService.isLoggedIn() ? "d-none" : "nav-item active"
                }
              >
                <Link
                  className="nav-link"
                  onClick={() => {
                    UserService.doLogin();
                  }}
                >
                  <button className="btn btn-sm btn-light drk noborder">
                    <i className="fas fa-sign-in-alt"></i> Login
                  </button>
                </Link>
              </li>
              <li
                className={
                  UserService.isLoggedIn() ? "nav-item active" : "d-none"
                }
              >
                <Link className="nav-link" to="/">
                  <button className="btn btn-sm btn-light drk noborder">
                    <i className="fa fa-user" aria-hidden="true"></i> Hi,{" "}
                    {UserService.getUsername()}
                  </button>
                </Link>
              </li>
              <li
                className={
                  UserService.isLoggedIn() ? "d-none" : "nav-item active"
                }
              >
                <Link
                  className="nav-link"
                  onClick={() => UserService.doSignUp()}
                >
                  <button className="btn btn-sm btn-light drk noborder">
                    <i className="fas fa-user-plus"></i> SignUp
                  </button>
                </Link>
              </li>
              {/* <li className="nav-item active">
          <Link className="nav-link" to="/">
            <button className="btn  btn-sm btn-light drk noborder">
            <i className="fas fa-envelope"></i>   Contact Us 
            </button>
          </Link>
        </li>   */}
              <li
                className={
                  UserService.isLoggedIn() ? "nav-item active" : "d-none"
                }
              >
                <Link
                  className="nav-link"
                  onClick={() => {
                    UserService.doLogout({
                      redirectUri: `${REACT_APP_REDIRECT_URI}`,
                    });
                    // console.log(UserService.getToken());
                  }}
                >
                  <button className="btn btn-sm btn-light drk noborder">
                    <i className="fas fa-sign-in-alt"></i> Logout
                  </button>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="true"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
      </nav>
    </div>
  );
}

export default Navbar;
